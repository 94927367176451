import { useLocation } from '@remix-run/react';
import { ID } from '@segment/analytics-next';
import React from 'react';
import { PageRoutes } from '~/constants/routes';

export const redditPixelRoutes = [PageRoutes.LANDING_PAGE, PageRoutes.PARENT_INFO, PageRoutes.SIGNUP, PageRoutes.LOGIN];

export default function useRedditScript({ anonymousId, email }: { anonymousId: ID; email?: string }) {
  const location = useLocation();
  const shouldUsePixel = redditPixelRoutes.includes(location.pathname as PageRoutes);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isError, setIsError] = React.useState<ErrorEvent | null>(null);

  React.useEffect(() => {
    if (anonymousId && shouldUsePixel && !document.getElementById('reddit-pixel')) {
      const script = document.createElement('script');
      script.id = 'reddit-pixel';
      script.innerHTML = `
      !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
      rdt('init', 'a2_frxlr5tvqeih', { email: '${email || ''}', externalId: '${anonymousId || ''}' });
      rdt('track', 'PageVisit');
    `;

      const onLoad = () => setIsLoaded(true);
      const onError = (err: ErrorEvent) => setIsError(err);
      script.addEventListener('load', onLoad);
      script.addEventListener('error', onError);
      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onLoad);
        script.removeEventListener('error', onError);
        document.body.removeChild(script);
      };
    }
  }, [anonymousId, email, shouldUsePixel]);

  return { isRedditLoaded: isLoaded, isError };
}
