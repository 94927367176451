import { cssBundleHref } from '@remix-run/css-bundle';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import stylesheetUrl from '~/css/tailwind.css?url';
import fontStyleSheetUrl from '~/css/fonts.css?url';
import landingImgSmall from './assets/landing-small-4x.webp';
import dropdownMenuStylesheet from '~/css/dropdownMenuStyles.css?url';
import { Link, Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from '@remix-run/react';
import ProgressBar from './components/progressBar/ProgressBar';
import React from 'react';
import { Toaster } from './components/ui/toaster';
import { getEnvironment } from './utils/getEnvironment';
import { segmentWriteKeys } from './constants/segment';
import useLoadSegment from './analytics/useLoadSegment';
import useSegmentPageView from './analytics/useSegmentPageView';
import useAnonymousId from './analytics/useAnonymousId';
import { commitSession, getSession, logout } from './sessions';
import { PROFILE_DATA, TOKEN } from './constants/cookies';
import { getUserProfile } from './utils/getUserProfile';
import { tallyIds } from './constants/tallyId';
import useTallyId from './hooks/useTallyId';
import useRedditScript from './hooks/useRedditScript';
import { ExternalScripts, ExternalScriptsHandle } from 'remix-utils/external-scripts';
import { PageRoutes } from './constants/routes';

export const links: LinksFunction = () => [
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
  { rel: 'stylesheet', href: stylesheetUrl },
  { rel: 'stylesheet', href: fontStyleSheetUrl },
  { rel: 'stylesheet', href: dropdownMenuStylesheet },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
  },
  {
    rel: 'preload',
    href: landingImgSmall,
    as: 'image',
  },
];

export const handle: ExternalScriptsHandle = {
  scripts: [{ src: 'https://tally.so/widgets/embed.js', async: true }],
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const environment = getEnvironment(url.hostname);
  const key = segmentWriteKeys[environment];
  const tallyId = tallyIds[environment];
  const session = await getSession(request);
  const token = session.get(TOKEN);

  try {
    if (token) {
      const profile = await getUserProfile(token);
      if (profile) {
        session.set(PROFILE_DATA, profile);
        return json(
          { email: profile.email, key, tallyId },
          {
            headers: {
              'Set-Cookie': await commitSession(session),
            },
          }
        );
      }
      return logout({ session });
    }
  } catch (error) {
    return logout({ session });
  }

  return json(
    { email: '', key, tallyId },
    {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    }
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { email, key, tallyId } = useLoaderData<typeof loader>();
  useLoadSegment({ key });
  useSegmentPageView();
  useTallyId(tallyId);
  const anonymousId = useAnonymousId();
  useRedditScript({ anonymousId, email });

  return (
    <html lang="en">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style data-fullcalendar />
        <Links />
      </head>
      <body id="app">
        {children}
        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />
        <ProgressBar />
        <Toaster />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}

export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);

  return (
    <html lang="en">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style data-fullcalendar />
        <Links />
      </head>
      <body id="app">
        <h1>
          Something went wrong - please refresh the page or <Link to={PageRoutes.LANDING_PAGE}>click here</Link>
        </h1>
        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />
        <ProgressBar />
        <Toaster />
      </body>
    </html>
  );
}
